// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// krunal firebase photography

// const firebaseConfig = {
//   apiKey: "AIzaSyBqJMemqlnZRxKQ_sLSpASjpEPI-JP-MLY",
//   authDomain: "shivohamphotography-a6605.firebaseapp.com",
//   projectId: "shivohamphotography-a6605",
//   storageBucket: "shivohamphotography-a6605.firebasestorage.app",
//   messagingSenderId: "1090657705467",
//   appId: "1:1090657705467:web:2eee517d7eb891b8ce7f5e",
//   measurementId: "G-R0SPDB9D52",
// };

// shivoham photography firebase

const firebaseConfig = {
  apiKey: "AIzaSyCZbbsJyrI90EBeuowUSeom7sWh0aDa0H0",
  authDomain: "shivohamphotography-2dcc2.firebaseapp.com",
  projectId: "shivohamphotography-2dcc2",
  storageBucket: "shivohamphotography-2dcc2.firebasestorage.app",
  messagingSenderId: "653427364102",
  appId: "1:653427364102:web:7a718b4de1ce09e0e8ce87",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Firestore reference
export const db = getFirestore(app);

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// export const auth = getAuth(app);

// // Firestore reference
// export const db = getFirestore(app);
