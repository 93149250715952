import React, { useEffect, useState } from "react";
import axios from "axios";
import { db } from "../../firebase"; // Adjust the path based on your Firebase configuration file
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Icon } from "@iconify/react/dist/iconify.js";
import { toast } from "react-toastify";
import { useLoad } from "../../hooks/useLoad";

const imageIdMapping = {
  TNPiemPjEB8BesLTq7Bp: "filmmaker",
  SB7SbYABAkdcOjm6mMU0: "about",
  "7JGO0tjt7yJmYdZsZ0Hs": "wedding",
  RPtyarncKGtfGC1i9PWW: "corporate",
  fBrCOoV8ElSJbDCpfJHp: "family",
  dYQDGO2v2iOr1Cxofdn8: "model",
  "7tvQOeKUeKK2ueFAmSjO": "product",
  vvEJyQEQ3Dsq2T35AKnM: "sport",
  WaGBx3zP2aXiodVnUqUf: "proposal",
  gbd2QzHFhnDGe9wj2pKu: "wild",
  u3mvRXcOOEOmou63gNZw: "services",
};

const ManageImages = () => {
  const { setLoad } = useLoad();
  const [imageUrls, setImageUrls] = useState({});
  // const [selectedImage, setSelectedImage] = useState(null);
  const [selectedType, setSelectedType] = useState(""); // Type of image being replaced
  const [selectedId, setSelectedId] = useState(null); // ID of the image being replaced
  const [selectedUrlIndex, setSelectedUrlIndex] = useState(null); // ID of the image being replaced

  // Fetch all images from Firebase based on mapping
  const fetchImages = async () => {
    try {
      const imagePromises = Object.keys(imageIdMapping).map(async (id) => {
        const docRef = doc(db, "images", id); // Adjust collection name if needed
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return { id, url: docSnap.data().imageUrls }; // Corrected field name
        } else {
          return { id, url: "" };
        }
      });
      const images = await Promise.all(imagePromises);
      const imageMap = images.reduce(
        (acc, { id, url }) => ({ ...acc, [id]: url }),
        {}
      );
      console.log(imageMap);
      setImageUrls(imageMap);
    } catch (error) {
      console.error("Error fetching images:", error);
      toast.error("Something Went Wrong ! Please try again later.");
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  // Upload and replace the selected image
  const handleUpload = async (selectedImage) => {
    console.log(selectedImage, selectedType, selectedId);
    if (!selectedImage || !selectedType || !selectedId) {
      toast.error("Please select an image and type!");
      return;
    }

    try {
      setLoad(true);
      // Upload to Cloudinary
      const formData = new FormData();
      formData.append("file", selectedImage);
      formData.append("upload_preset", "ml_default");
      formData.append("cloud_name", "dnhugy20f");

      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dnhugy20f/image/upload",
        formData
      );
      const newUrl = response.data.secure_url;

      // Fetch the existing document from Firebase
      const docRef = doc(db, "images", selectedId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        toast.error("Document not found.");
        setLoad(false);
        return;
      }

      // Replace the specific image in the array
      const existingUrls = docSnap.data().imageUrls || [];
      const updatedUrls = existingUrls.map(
        (url, index) => (index === selectedUrlIndex ? newUrl : url) // Replace the first image (index 0) with the new one
      );

      // Update Firebase with the new array
      await updateDoc(docRef, { imageUrls: updatedUrls });

      // Update local state
      setImageUrls((prev) => ({ ...prev, [selectedId]: updatedUrls }));

      toast.success(`${selectedType} image updated successfully!`);
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Failed to upload image.");
    } finally {
      setLoad(false);
      setSelectedType("");
      setSelectedId(null); // Clear the selected image ID
    }
  };

  const handleLabelClick = (type, id, index) => {
    // console.log(type, id);
    // Set the type and ID when the label is clicked
    setSelectedType(type);
    setSelectedId(id);
    setSelectedUrlIndex(index);
  };

  // Handle specific file selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    handleUpload(file);
  };

  return (
    <div className="w-[90%] lg:w-[95%] mx-auto pt-[100px] my-10">
      <h1 className="text-4xl font-semibold text-textcolor mb-5">
        Image Management
      </h1>

      {/* Display Images */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
        {Object.entries(imageIdMapping).map(([id, type]) => (
          <div
            key={id}
            className="relative border p-2 rounded shadow h-fit transition-all transform img-hover-effect duration-300"
          >
            {(imageUrls[id] || []).map((url, index) => (
              <div key={index} className="relative">
                <img
                  src={url || "https://via.placeholder.com/150"}
                  alt={`${type} ${index + 1}`}
                  className="w-full h-40 object-cover rounded mb-2 transition-all hover:h-full duration-300 ease-in-out transform"
                />
                {/* Edit icon to change image */}
                <label
                  htmlFor="image"
                  className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-lg cursor-pointer"
                  onClick={() => handleLabelClick(type, id, index)} // Trigger type and ID set
                >
                  <Icon
                    icon={"material-symbols:edit"}
                    className="text-textcolor text-xl"
                  />
                </label>
              </div>
            ))}
            <p className="text-center mt-2 capitalize">{type}</p>
          </div>
        ))}
      </div>

      {/* Replace Specific Image */}
      <div className="mb-6">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="hidden"
          name="image"
          id="image"
        />
      </div>
    </div>
  );
};

export default ManageImages;
