import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./Page/App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Admin from "./Page/Admin/Admin";
import ManageInquiries from "./Page/Admin/ManageInquiries";
import Auth from "./Page/Admin/Auth";
import ProtectedRoute from "./Page/Admin/ProtectedRoute";
import ManageImages from "./Page/Admin/ManageImages";
import { LoadProvider } from "./hooks/useLoad";
import Loader from "./component/Elements/Loader";
import { ToastContainer } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoadProvider>
    <Router>
      <Loader />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="auth" element={<Auth />}></Route>
        <Route
          path="admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        >
          <Route path="inquiry" element={<ManageInquiries />} />
          <Route path="images" element={<ManageImages />} />
        </Route>
      </Routes>
    </Router>
  </LoadProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
