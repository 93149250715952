// import React, { useEffect, useRef, useState } from "react";
// import { Link } from "react-scroll";
// // import logo from "../assets/logo.png";
// import logo from "../assets/logo_1.jpg";
// import { Link as RouterLink, useNavigate } from "react-router-dom";
// import { Icon } from "@iconify/react";
// import { onAuthStateChanged } from "firebase/auth";
// import { auth } from "../firebase";
// import { getAuth, signOut } from "firebase/auth";

// export default function Header({ budgetModal }) {
//   const navbarRef = useRef(null);
//   const navigate = useNavigate();

//   const [isOpen, setIsOpen] = useState(false);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       setIsAuthenticated(!!user); // User is authenticated if user object exists
//     });

//     return () => unsubscribe(); // Clean up the listener
//   }, []);

//   const logoutUser = () => {
//     const auth = getAuth();
//     signOut(auth)
//       .then(() => {
//         console.log("Admin logged out successfully");
//         setIsOpen(false);
//         navigate("/"); // Redirect to the login page after logout
//       })
//       .catch((error) => {
//         console.error("Error signing out: ", error);
//       });
//   };

//   const toggleDropdown = () => {
//     setIsOpen((prevState) => !prevState);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (navbarRef.current && !navbarRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };

//     document.addEventListener("click", handleClickOutside);

//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, []);

//   return (
//     <nav
//       className={`fixed top-0 left-0 w-full ${budgetModal ? "z-30" : "z-50"}`}
//     >
//       <div className="flex flex-col lg:flex-row items-center justify-around lg:bg-[#ffffff]">
//         <div className="flex w-full lg:w-fit items-center justify-around p-1 bg-[#ffffff]">
//           <Link
//             to="home"
//             smooth={true}
//             duration={500}
//             onClick={() => setIsOpen(false)}
//             className="py-2"
//           >
//             <img src={logo} alt="Logo" className="h-20 cursor-pointer" />
//           </Link>

//           <div className="block lg:hidden" ref={navbarRef}>
//             <button
//               onClick={toggleDropdown}
//               className="flex items-center px-3 py-2 hover:bg-textcolor hover:text-[#ffffff] border rounded text-2xl text-textcolor border-textcolor  "
//             >
//               <Icon icon="material-symbols:menu" />
//             </button>
//           </div>
//         </div>

//         <div
//           className={`transition-opacity duration-300 ease-in-out block bg-[#ffffff] lg:flex lg:items-center lg:w-auto  ${
//             isOpen
//               ? "ring-1 w-[90%] lg:ring-0 ring-textcolor rounded p-4 lg:p-0 opacity-100"
//               : "hidden w-full opacity-0 lg:opacity-100 lg:w-auto"
//           }`}
//         >
//           <div
//             className={`text-xl font-medium  text-textcolor lg:text-2xl lg:flex-grow  space-y-2 lg:space-y-0 space-x-0 lg:space-x-10`}
//           >
//             <Link
//               to="home"
//               smooth={true}
//               duration={500}
//               className="block lg:inline-block cursor-pointer"
//               onClick={() => setIsOpen(false)}
//             >
//               Home
//             </Link>
//             <Link
//               to="about"
//               smooth={true}
//               duration={500}
//               className="block lg:inline-block cursor-pointer"
//               onClick={() => setIsOpen(false)}
//             >
//               About Me
//             </Link>
//             <Link
//               to="portfolio"
//               smooth={true}
//               duration={500}
//               className="block lg:inline-block cursor-pointer"
//               onClick={() => setIsOpen(false)}
//             >
//               Portfolio
//             </Link>

//             <Link
//               to="services"
//               smooth={true}
//               duration={500}
//               className="block lg:inline-block cursor-pointer"
//               onClick={() => setIsOpen(false)}
//             >
//               Our Services
//             </Link>

//             {isAuthenticated && (
//               <RouterLink
//                 to="/admin"
//                 className="block lg:inline-block cursor-pointer"
//               >
//                 Admin
//               </RouterLink>
//             )}

//             <Link
//               to="inquiry"
//               smooth={true}
//               duration={500}
//               className="block lg:inline-block lg:px-5 lg:py-2 lg:rounded-full lg:shadow-lg lg:ring-0 lg:border-2 lg:border-transparent  lg:hover:border-2 lg:hover:border-textcolor lg:transition duration-300 lg:ease-in-out cursor-pointer"
//               style={{
//                 boxShadow: window.innerWidth >= 1024 ? "0 0 4em #1132704D" : "",
//               }}
//               onClick={() => setIsOpen(false)}
//             >
//               Contact Us
//             </Link>

//             {isAuthenticated && (
//               <div
//                 className="block lg:inline-block lg:px-5 lg:py-2 lg:rounded-full lg:shadow-lg lg:ring-0 lg:border-2 lg:border-transparent  lg:hover:border-2 lg:hover:border-textcolor lg:transition duration-300 lg:ease-in-out cursor-pointer"
//                 style={{
//                   boxShadow:
//                     window.innerWidth >= 1024 ? "0 0 4em #1132704D" : "",
//                 }}
//                 onClick={logoutUser}
//               >
//                 LogOut
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// }

import React, { useEffect, useRef, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { onAuthStateChanged, getAuth, signOut } from "firebase/auth";
import logo from "../assets/logo_1.jpg";
import { auth } from "../firebase";

export default function Header({ budgetModal }) {
  const navbarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation(); // Get current path

  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // User is authenticated if user object exists
    });

    return () => unsubscribe(); // Clean up the listener
  }, []);

  const logoutUser = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Admin logged out successfully");
        setIsOpen(false);
        navigate("/"); // Redirect to the login page after logout
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const isHomePage = location.pathname === "/"; // Check if on home page

  return (
    <nav
      className={`fixed top-0 left-0 w-full ${budgetModal ? "z-30" : "z-50"}`}
    >
      <div className="flex flex-col lg:flex-row items-center justify-around lg:bg-[#ffffff]">
        <div className="flex w-full lg:w-fit items-center justify-around p-1 bg-[#ffffff]">
          {isHomePage ? (
            <ScrollLink
              to="home"
              smooth={true}
              duration={500}
              onClick={() => setIsOpen(false)}
              className="py-2"
            >
              <img src={logo} alt="Logo" className="h-20 cursor-pointer" />
            </ScrollLink>
          ) : (
            <RouterLink to="/" className="py-2">
              <img src={logo} alt="Logo" className="h-20 cursor-pointer" />
            </RouterLink>
          )}

          <div className="block lg:hidden" ref={navbarRef}>
            <button
              onClick={toggleDropdown}
              className="flex items-center px-3 py-2 hover:bg-textcolor hover:text-[#ffffff] border rounded text-2xl text-textcolor border-textcolor  "
            >
              <Icon icon="material-symbols:menu" />
            </button>
          </div>
        </div>

        <div
          className={`transition-opacity duration-300 ease-in-out block bg-[#ffffff] lg:flex lg:items-center lg:w-auto  ${
            isOpen
              ? "ring-1 w-[90%] lg:ring-0 ring-textcolor rounded p-4 lg:p-0 opacity-100"
              : "hidden w-full opacity-0 lg:opacity-100 lg:w-auto"
          }`}
        >
          <div
            className={`text-xl font-medium  text-textcolor lg:text-2xl lg:flex-grow  space-y-2 lg:space-y-0 space-x-0 lg:space-x-10`}
          >
            {isHomePage ? (
              <>
                <ScrollLink
                  to="home"
                  smooth={true}
                  duration={500}
                  className="block lg:inline-block cursor-pointer"
                  onClick={() => setIsOpen(false)}
                >
                  Home
                </ScrollLink>
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={500}
                  className="block lg:inline-block cursor-pointer"
                  onClick={() => setIsOpen(false)}
                >
                  About Me
                </ScrollLink>
                <ScrollLink
                  to="portfolio"
                  smooth={true}
                  duration={500}
                  className="block lg:inline-block cursor-pointer"
                  onClick={() => setIsOpen(false)}
                >
                  Portfolio
                </ScrollLink>
                <ScrollLink
                  to="services"
                  smooth={true}
                  duration={500}
                  className="block lg:inline-block cursor-pointer"
                  onClick={() => setIsOpen(false)}
                >
                  Our Services
                </ScrollLink>
              </>
            ) : (
              <>
                <RouterLink
                  to="/"
                  className="block lg:inline-block cursor-pointer"
                >
                  Home
                </RouterLink>
              </>
            )}

            {isAuthenticated && (
              <>
                <RouterLink
                  to="/admin/inquiry"
                  className="block lg:inline-block cursor-pointer"
                >
                  Inquiry
                </RouterLink>
                <RouterLink
                  to="/admin/images"
                  className="block lg:inline-block cursor-pointer"
                >
                  Images
                </RouterLink>
                {/* <RouterLink
                  to="/admin"
                  className="block lg:inline-block cursor-pointer"
                >
                  Admin
                </RouterLink> */}
              </>
            )}

            {isHomePage && (
              <ScrollLink
                to="inquiry"
                smooth={true}
                duration={500}
                className="block lg:inline-block lg:px-5 lg:py-2 lg:rounded-full lg:shadow-lg lg:ring-0 lg:border-2 lg:border-transparent  lg:hover:border-2 lg:hover:border-textcolor lg:transition duration-300 lg:ease-in-out cursor-pointer"
                style={{
                  boxShadow:
                    window.innerWidth >= 1024 ? "0 0 4em #1132704D" : "",
                }}
                onClick={() => setIsOpen(false)}
              >
                Contact Us
              </ScrollLink>
            )}

            {isAuthenticated && (
              <div
                className="block lg:inline-block lg:px-5 lg:py-2 lg:rounded-full lg:shadow-lg lg:ring-0 lg:border-2 lg:border-transparent  lg:hover:border-2 lg:hover:border-textcolor lg:transition duration-300 lg:ease-in-out cursor-pointer"
                style={{
                  boxShadow:
                    window.innerWidth >= 1024 ? "0 0 4em #1132704D" : "",
                }}
                onClick={logoutUser}
              >
                LogOut
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
