/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import Filmmaker from "../component/Filmmaker";
import { db } from "../firebase"; // Adjust path as needed
import { collection, getDocs } from "firebase/firestore";
import About from "../component/About";
import Portfolio from "../component/Portfolio";
import Ourservices from "../component/Ourservices";
import Faq from "../component/Faq";
import Footer from "../component/Footer";
import "../style.css";
import Inquiry from "../component/Inquiry";
import BudgetModal from "../component/BudgetModal";
import {
  aboutImages,
  filmMakerImages,
  corporateImages,
  familyImages,
  modelImages,
  productImages,
  sportImages,
  proposalImages,
  weddingImages,
  wildImages,
  serviceImages,
} from "../constants/constants";

function App() {
  const [budgetModal, setBudgetModal] = useState(false);
  const [imageUrls, setImageUrls] = useState({
    filmmaker: [],
    about: [],
    wedding: [],
    corporate: [],
    family: [],
    model: [],
    product: [],
    sport: [],
    proposal: [],
    wild: [],
    services: [],
  });

  // Mapping of Firestore document IDs to state keys
  const imageIdMapping = {
    TNPiemPjEB8BesLTq7Bp: "filmmaker",
    SB7SbYABAkdcOjm6mMU0: "about",
    "7JGO0tjt7yJmYdZsZ0Hs": "wedding",
    RPtyarncKGtfGC1i9PWW: "corporate",
    fBrCOoV8ElSJbDCpfJHp: "family",
    dYQDGO2v2iOr1Cxofdn8: "model",
    "7tvQOeKUeKK2ueFAmSjO": "product",
    vvEJyQEQ3Dsq2T35AKnM: "sport",
    WaGBx3zP2aXiodVnUqUf: "proposal",
    gbd2QzHFhnDGe9wj2pKu: "wild",
    u3mvRXcOOEOmou63gNZw: "services",
  };

  const defaultImages = {
    filmmaker: filmMakerImages,
    about: aboutImages,
    wedding: weddingImages,
    corporate: corporateImages,
    family: familyImages,
    model: modelImages,
    product: productImages,
    sport: sportImages,
    proposal: proposalImages,
    wild: wildImages,
    services: serviceImages,
  };

  const fetchImages = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "images"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const updatedUrls = { ...imageUrls };

      // Map Firestore data to state
      data.forEach((item) => {
        const key = imageIdMapping[item.id];
        if (key) {
          updatedUrls[key] = item.imageUrls;
        }
      });

      setImageUrls(updatedUrls);
    } catch (error) {
      console.error("Error fetching images:", error);
      setImageUrls(defaultImages); // Fallback to default images
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handelBudgetModal = () => {
    setBudgetModal(!budgetModal);
  };
  return (
    <div className="App font-sans relative">
      <Header budgetModal={budgetModal} />

      {/* Main content */}
      <Filmmaker images={imageUrls.filmmaker} />
      <About images={imageUrls.about} />
      <Portfolio
        weddingImages={imageUrls.wedding}
        corporateImages={imageUrls.corporate}
        familyImages={imageUrls.family}
        modelImages={imageUrls.model}
        productImages={imageUrls.product}
        sportImages={imageUrls.sport}
        proposalImages={imageUrls.proposal}
        wildImages={imageUrls.wild}
      />
      <Ourservices serviceImages={imageUrls.services} />
      <Faq />
      <Inquiry handelBudgetModal={handelBudgetModal} />
      {/* <Contactus handelBudgetModal={handelBudgetModal} /> */}
      <BudgetModal
        handelBudgetModal={handelBudgetModal}
        budgetModal={budgetModal}
      />

      <Footer />
    </div>
  );
}

export default App;
