import about0 from "../assets/about/about0.jpeg";
import about1 from "../assets/about/about1.jpeg";
import about2 from "../assets/about/about2.jpeg";
import about3 from "../assets/about/about3.png";
import first from "../assets/filmMaker/first.png";
import second from "../assets/filmMaker/second.jpeg";
import third from "../assets/filmMaker/third.jpeg";
import forth from "../assets/filmMaker/forth.jpg";
import wedding0 from "../assets/portfolio/wedding/wedding0.jpeg";
import wedding1 from "../assets/portfolio/wedding/wedding1.jpeg";
import wedding2 from "../assets/portfolio/wedding/wedding2.jpeg";
import wedding3 from "../assets/portfolio/wedding/wedding3.jpeg";
import proposal0 from "../assets/portfolio/proposal/proposal0.png";
import proposal1 from "../assets/portfolio/proposal/proposal1.png";
import proposal2 from "../assets/portfolio/proposal/proposal2.png";
import proposal3 from "../assets/portfolio/proposal/proposal3.png";
import corporate0 from "../assets/portfolio/corporate/corporate0.png";
import corporate1 from "../assets/portfolio/corporate/corporate1.png";
import corporate2 from "../assets/portfolio/corporate/corporate2.png";
import corporate3 from "../assets/portfolio/corporate/corporate3.png";
import family0 from "../assets/portfolio/family/family0.png";
import family1 from "../assets/portfolio/family/family1.png";
import family2 from "../assets/portfolio/family/family2.png";
import family3 from "../assets/portfolio/family/family3.png";
import wild0 from "../assets/portfolio/wild/wild0.png";
import wild1 from "../assets/portfolio/wild/wild1.png";
import wild2 from "../assets/portfolio/wild/wild2.png";
import wild3 from "../assets/portfolio/wild/wild3.png";
import product0 from "../assets/portfolio/product/product0.png";
import product1 from "../assets/portfolio/product/product1.png";
import product2 from "../assets/portfolio/product/product2.png";
import product3 from "../assets/portfolio/product/product3.png";
import model0 from "../assets/portfolio/model/model0.png";
import model1 from "../assets/portfolio/model/model1.png";
import model2 from "../assets/portfolio/model/model2.png";
import model3 from "../assets/portfolio/model/model3.png";
import sport0 from "../assets/portfolio/sport/sport0.png";
import sport1 from "../assets/portfolio/sport/sport1.png";
import sport2 from "../assets/portfolio/sport/sport2.png";
import sport3 from "../assets/portfolio/sport/sport3.png";
import Service0 from "../assets/service/Service0.png";
import Service1 from "../assets/service/Service1.png";
import Service2 from "../assets/service/Service2.png";
import Service3 from "../assets/service/Service3.png";
import Service4 from "../assets/service/Service4.png";
import Service5 from "../assets/service/Service5.png";

export const aboutImages = [about0, about1, about2, about3];
export const filmMakerImages = [first, second, third, forth];
export const weddingImages = [wedding0, wedding1, wedding2, wedding3];
export const proposalImages = [proposal0, proposal1, proposal2, proposal3];
export const corporateImages = [corporate0, corporate1, corporate2, corporate3];
export const familyImages = [family0, family1, family2, family3];
export const wildImages = [wild0, wild1, wild2, wild3];
export const productImages = [product0, product1, product2, product3];
export const modelImages = [model0, model1, model2, model3];
export const sportImages = [sport0, sport1, sport2, sport3];
export const serviceImages = [
  Service0,
  Service1,
  Service2,
  Service3,
  Service4,
  Service5,
];
