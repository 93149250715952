import React, { useState } from "react";

import Accordion from "./Accordion";

const collection = [
  {
    id: 1,
    question: "What services do you offer?",
    answer:
      "We provide a wide range of photography services, including portrait photography, wedding photography, event photography, product photography, and landscape photography. Our team is also skilled in post-production editing to ensure your images are of the highest quality.",
  },
  {
    id: 2,
    question: "How do I book a session?",
    answer:
      "We provide a wide range of photography services, including portrait photography, wedding photography, event photography, product photography, and landscape photography. Our team is also skilled in post-production editing to ensure your images are of the highest quality.",
  },
  {
    id: 3,
    question: "Do you travel for shoots?",
    answer:
      "We provide a wide range of photography services, including portrait photography, wedding photography, event photography, product photography, and landscape photography. Our team is also skilled in post-production editing to ensure your images are of the highest quality.",
  },
  {
    id: 4,
    question: "What is your pricing structure?",
    answer:
      "We provide a wide range of photography services, including portrait photography, wedding photography, event photography, product photography, and landscape photography. Our team is also skilled in post-production editing to ensure your images are of the highest quality.",
  },
  // {
  //   id: 5,
  //   question: "What services do you offer?",
  //   answer:
  //     "We provide a wide range of photography services, including portrait photography, wedding photography, event photography, product photography, and landscape photography. Our team is also skilled in post-production editing to ensure your images are of the highest quality.",
  // },
];

export default function Faq() {
  const [data, setdata] = useState(collection);
  const [activeId, setActiveId] = useState(null);

  return (
    <div className="w-full mt-20">
      <div className="text-5xl lg:text-6xl text-textcolor text-center font-semibold mb-10">
        FAQ
      </div>

      {/* this is represent the main container of faq section */}
      <div className="w-[90%] md:w-[85%] lg:w-[75%] mx-auto space-y-1.5">
        {/* hare display all the faq */}
        {data.map((faq, index) => {
          return (
            <Accordion
              key={faq.id}
              id={faq.id}
              question={faq.question}
              answer={faq.answer}
              isOpen={activeId === faq.id}
              onToggle={() => setActiveId(activeId === faq.id ? null : faq.id)}
            />
          );
        })}
      </div>
    </div>
  );
}
