import React, { useEffect, useState } from "react";
import { db } from "../../firebase"; // Adjust path as needed
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { Icon } from "@iconify/react/dist/iconify.js";

const ManageInquiries = () => {
  const [inquiries, setInquiries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInquiryId, setSelectedInquiryId] = useState(null);
  const [comment, setComment] = useState("");

  // Fetch inquiries from Firestore
  const fetchInquiries = async () => {
    console.log("Fetching inquiries...");
    const querySnapshot = await getDocs(collection(db, "inquiries"));
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log(data);
    setInquiries(data);
  };

  // Update inquiry in Firestore
  const updateInquiry = async (id, updates) => {
    const inquiryRef = doc(db, "inquiries", id);
    await updateDoc(inquiryRef, updates);
    fetchInquiries(); // Refresh data
  };

  // Handle opening the modal
  const handleOpenModal = (id, existingComment) => {
    setSelectedInquiryId(id);
    setComment(existingComment || ""); // Pre-fill with existing comment if available
    setIsModalOpen(true);
  };

  // Handle modal submit
  const handleSubmitComment = () => {
    if (selectedInquiryId) {
      updateInquiry(selectedInquiryId, { comment });
    }
    setIsModalOpen(false);
    setComment(""); // Clear the comment after submission
    setSelectedInquiryId(null);
  };

  useEffect(() => {
    fetchInquiries();
  }, []);

  return (
    <>
      <div className="w-[90%] lg:w-[95%] mx-auto pt-[100px] my-10 ">
        <h1 className="text-4xl font-semibold text-textcolor mb-5 ">
          Inquiry Management
        </h1>

        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="overflow-x-auto max-h-[500px]">
            {" "}
            {/* Adjust max height as needed */}
            <table
              className="w-full text-left border-collapse text-sm md:text-base table-auto"
              cellPadding={15}
            >
              <thead className="bg-textcolor text-white sticky top-0 ">
                {" "}
                {/* Sticky header */}
                <tr>
                  <th>Service Type</th>
                  <th>Type of Photography</th>
                  <th>Email</th>
                  <th>Message</th>
                  <th>Status</th>
                  <th>Comment</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody className="overflow-y-auto max-h-[300px]">
                {" "}
                {/* Make tbody scrollable */}
                {inquiries.map((inquiry) => (
                  <tr key={inquiry.id} className="border-b">
                    <td>{inquiry.serviceType}</td>
                    <td>{inquiry.typeOfPhotography}</td>
                    <td>{inquiry.email}</td>
                    <td className="max-w-56">
                      {inquiry.message
                        ? inquiry.message.length > 50
                          ? `${inquiry.message.slice(0, 50)}...`
                          : inquiry.message
                        : "No message"}
                    </td>
                    <td
                      className={`font-medium ${
                        inquiry.status === 0
                          ? "text-yellow-500"
                          : inquiry.status === 1
                          ? "text-textcolor2"
                          : "text-textcolor"
                      }`}
                    >
                      {inquiry.status === 0
                        ? "Pending"
                        : inquiry.status === 1
                        ? "Completed"
                        : "Processing"}
                    </td>

                    <td>
                      {inquiry.comment
                        ? inquiry.comment.length > 40
                          ? `${inquiry.comment.slice(0, 40)}...`
                          : inquiry.comment
                        : "No Comment"}
                    </td>
                    <td className="flex gap-2">
                      {(inquiry.status === 0 || inquiry.status === 2) && (
                        <button
                          onClick={() =>
                            updateInquiry(inquiry.id, { status: 1 })
                          }
                          className="px-3 py-1 bg-textcolor2 text-white rounded-lg shadow hover:bg-green-600 transition"
                        >
                          Complete
                        </button>
                      )}
                      {inquiry.status === 0 && (
                        <button
                          onClick={() =>
                            updateInquiry(inquiry.id, { status: 2 })
                          }
                          className="px-3 py-1 bg-textcolor text-white rounded-lg shadow hover:bg-textcolor transition"
                        >
                          Processing
                        </button>
                      )}
                      <button
                        onClick={() =>
                          handleOpenModal(inquiry.id, inquiry.comment)
                        }
                        className="px-3 py-1 bg-gray-300 text-textcolor rounded-lg shadow hover:bg-gray-400 transition"
                      >
                        Add Comment
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg  w-[90%] md:w-[60%]">
            <div className="flex justify-between">
              <h2 className="font-semibold text-xl mb-4 text-textcolor">
                Add Comment
              </h2>
              <Icon
                icon="zondicons:close-solid"
                className="text-2xl text-textcolor cursor-pointer"
                onClick={() => setIsModalOpen(false)}
              />
            </div>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-full h-24 p-2 ring-[1px] ring-gray-300 rounded focus:ring-[1px] focus:outline-none focus:ring-textcolor "
              placeholder="Enter your comment"
            />
            <div className="mt-4 flex justify-end gap-2">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmitComment}
                className="px-4 py-2 bg-textcolor2 text-white rounded hover:bg-green-600"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageInquiries;
