import React from "react";
import { Icon } from "@iconify/react";
import GetBudget from "./GetBudget";
import "react-toastify/dist/ReactToastify.css";
// import { schema } from "@hookform/resolvers/ajv/src/__tests__/__fixtures__/data.js";

export default function Footer({ handelBudgetModal }) {
  return (
    <div className="w-full bg-[#1132701A] rounded-t-2xl ">
      <div className="w-[90%] md:w-[85%] lg:w-[75%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-auto py-8 md:py-12 gap-10 ">
        <div className="w-full text-textcolor space-y-1.5 flex flex-col lg:items-start">
          <div className="text-4xl font-semibold">Get In Touch</div>
          <div>
            <a
              href="mailto:Contact@shivohamphotography.com"
              className="text-xl font-normal"
            >
              contact@shivohamphotography.com
            </a>
          </div>
          <div>
            <a href="tel:+33745433761" className="text-xl font-normal">
              (+33)-7 45 43 37 61
            </a>
          </div>
        </div>
        <div className="w-full text-textcolor space-y-1.5 flex flex-col lg:items-center">
          <div className="text-4xl font-semibold"> Visit Us</div>
          <div className="text-xl font-normal"> Paris, France</div>
        </div>
        <div className="w-full text-textcolor space-y-1.5 flex flex-col lg:items-end">
          <div className="text-4xl font-semibold"> My social links</div>
          <div className="text-xl font-normal flex gap-2">
            <a
              href="https://www.facebook.com/people/Shivoham-Photography/100087683897684/"
              target="_blank"
              rel="noopener noreferrer"
              className="h-10 w-10 bg-[#ffffff] rounded-full cursor-pointer flex items-center justify-center text-xl font-normal"
            >
              <Icon icon="ri:facebook-fill" />
            </a>

            <a
              href="https://www.youtube.com/@shivohamphotography"
              target="_blank"
              rel="noopener noreferrer"
              className="h-10 w-10 bg-[#ffffff] rounded-full cursor-pointer flex items-center justify-center text-xl font-normal"
            >
              <Icon icon="line-md:youtube-filled" />
            </a>

            <a
              href="https://www.instagram.com/shivohamphotography/"
              target="_blank"
              rel="noopener noreferrer"
              className="h-10 w-10 bg-[#ffffff] rounded-full cursor-pointer flex items-center justify-center text-xl font-normal"
            >
              <Icon icon="basil:instagram-solid" />
            </a>

            <a
              href="https://wa.me/33745433761"
              target="_blank"
              rel="noopener noreferrer"
              className="h-10 w-10 bg-[#ffffff] rounded-full cursor-pointer flex items-center justify-center text-xl font-normal"
            >
              <Icon icon="ri:whatsapp-fill" />
            </a>

            <a
              href="tel:+33745433761"
              className="h-10 w-10 bg-[#ffffff] rounded-full cursor-pointer flex items-center justify-center text-xl font-normal"
            >
              <Icon icon="ic:baseline-call" />
            </a>
            <GetBudget handelBudgetModal={handelBudgetModal} />
          </div>
        </div>
      </div>

      <a
        href="https://www.tinyscript.in/"
        className="text-center text-textcolor text-base py-2 block"
        target="_blank"
        rel="noopener noreferrer"
      >
        Technology Partner - Tiny Script Soft Tech Pvt. Ltd.
      </a>
      <div className="text-center text-textcolor text-base py-3">
        © 2023 Shivoham Studio.
      </div>
    </div>
  );
}
