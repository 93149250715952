import React, { useEffect } from "react";
import { useLoad } from "../../hooks/useLoad"; // Assuming you're using the context for load state

const Loader = () => {
  const { load } = useLoad(); // Get the load state from context

  useEffect(() => {
    // When load is true, hide body overflow, else restore it
    if (load) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = "auto"; // Allow scrolling when loading is false
    }

    // Clean up when the component is unmounted or load changes
    return () => {
      document.body.style.overflow = "auto"; // Ensure overflow is restored if the component unmounts
    };
  }, [load]); // Dependency array ensures this effect runs when load changes

  return (
    load && (
      <div className="fixed inset-0 z-50 bg-gray-400/45 flex items-center justify-center">
        <div className="loader"></div>
      </div>
    )
  );
};

export default Loader;
