import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
export default function About({ images }) {
  // State to track the current image indices
  const [indices, setIndices] = useState([0, 1, 2]);
  // State to control the opacity for fading effect
  const [opacity, setOpacity] = useState([1, 1, 1]);

  // Circular shift the images every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setOpacity([0.7, 0.7, 0.7]); // Start fading out

      // Set a timeout to change the images after fading out
      setTimeout(() => {
        setIndices((prevIndices) => {
          return [
            prevIndices[1],
            prevIndices[2],
            (prevIndices[2] + 1) % images.length,
          ];
        });
        setOpacity([1, 1, 1]); // Fade in new images
      }, 1000); // Match this duration to the fade-out duration
    }, 3000); // Change images every 3 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [images.length]);

  return (
    <div className="flex flex-col md:flex-row gap-10 md:gap-12 lg:gap-16 items-center pt-14">
      <div className="w-full lg:w-[60%] grid grid-cols-2 gap-8 md:gap-12 lg:gap-16  justify-center items-center">
        <div className="relative md:-left-16">
          <img
            className="w-full h-[500px] rounded-[50px] transition-opacity duration-1000 ease-in-out"
            src={images[indices[0]]}
            alt="AboutImg1"
            style={{ opacity: opacity[0] }}
          />
        </div>
        <div className="hidden md:block row-span-2 relative -left-16">
          <img
            className="w-full h-[500px] rounded-[50px] transition-opacity duration-1000 ease-in-out"
            src={images[indices[1]]}
            alt="AboutImg2"
            style={{ opacity: opacity[1] }}
          />
        </div>
        <div className="relative md:-left-16">
          <img
            className="w-full h-[500px] rounded-[50px]  transition-opacity duration-1000 ease-in-out"
            src={images[indices[2]]}
            alt="AboutImg2"
            style={{ opacity: opacity[2] }}
          />
        </div>
      </div>
      <div className="w-full lg:w-[40%]">
        <div className="w-[90%] md:w-[80%] lg:w-[60%] relative mx-auto lg:mx-0">
          <div className="text-5xl lg:text-6xl text-center  text-textcolor font-semibold ">
            About Me
          </div>
          <div className="text-[#90B82D] text-justify font-normal text-base lg:text-lg my-7">
            Living in the laps of nature, surrounded by wildlife and innocent
            creatures inspired me to understand them very deeply and capture
            their moments. To Follow this passion, I studied
            at&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Australian
            Academy of Photography. While learning with this institute, I got
            chance to expand my skills and practice. Using camera and clicking
            photos was never a purpose of earnings but it was emotional
            attachment to the nature and wildlife. During my learning I realized
            that it is an ocean and there are so many different areas of
            learning. Now it has been a major part of the life. The day when I
            don't touch my camera I feel like the day is without zero progress.”
            <br />
            <br />
            Our Purpose should be about learning new subjects and topics no
            matter in which field you are, the day you stop learning you stop
            progressing. I am really very thankful to people who helped and
            still helping me to become more creative and progressive.”
            <br />
            <br />
            Thank you very much.
          </div>
          <Link
            to="portfolio"
            smooth={true}
            duration={500}
            className="px-5 py-2.5 text-textcolor font-medium text-xl md:tetx-2xl bg-[#ffffff] w-fit rounded-full shadow-lg ring-0 border-2 border-transparent hover:border-2 hover:border-textcolor transition duration-300 ease-in-out cursor-pointer"
            style={{ boxShadow: "0 0 4em #1132704D" }}
          >
            View My Works
          </Link>
        </div>
      </div>
    </div>
  );
}
