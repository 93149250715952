import React, { createContext, useState, useContext } from "react";

// Create the LoadContext
const LoadContext = createContext();

// Create the custom hook to use LoadContext
export const useLoad = () => {
  const context = useContext(LoadContext);

  if (!context) {
    throw new Error("useLoad must be used within a LoadProvider");
  }

  return context;
};

// Create the LoadProvider component
export const LoadProvider = ({ children }) => {
  const [load, setLoad] = useState(false); // Initial loading state
  const [file, setFile] = useState(null); // To store the selected file

  return (
    <LoadContext.Provider value={{ load, setLoad, file, setFile }}>
      {children}
    </LoadContext.Provider>
  );
};
