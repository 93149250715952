/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

const Filmmaker = ({ images }) => {
  // State to track the current image indices
  const [indices, setIndices] = useState([0, 1, 2]);
  // State to control the opacity for fading effect
  const [opacity, setOpacity] = useState([1, 1, 1]);

  // Circular shift the images every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setOpacity([0.7, 0.7, 0.7]); // Start fading out

      // Set a timeout to change the images after fading out
      setTimeout(() => {
        setIndices((prevIndices) => {
          return [
            prevIndices[1],
            prevIndices[2],
            (prevIndices[2] + 1) % images.length,
          ];
        });
        setOpacity([1, 1, 1]); // Fade in new images
      }, 1000); // Match this duration to the fade-out duration
    }, 3000); // Change images every 3 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [images.length]);

  return (
    <div>
      <div
        className="flex flex-col md:flex-row items-center md:gap-5 pt-32"
        id="home"
      >
        <div className="space-y-7 lg:space-y-14 flex flex-col items-center  w-full md:w-[40%] my-10">
          <div className="text-center w-full text-5xl lg:text-7xl font-semibold text-textcolor">
            Photographer & Film maker
          </div>
          <Link
            to="inquiry"
            smooth={true}
            duration={500}
            className="px-5 py-2 text-textcolor font-medium text-xl md:text-2xl lg:text-3xl bg-[#ffffff] w-fit rounded-full shadow-lg ring-0 border-2 border-transparent hover:border-2 hover:border-textcolor transition duration-300 ease-in-out cursor-pointer"
            style={{ boxShadow: "0 0 2em #1132704D" }}
          >
            Contact Us
          </Link>
        </div>

        <div className="grid grid-cols-8 w-full md:w-[60%] gap-5 justify-center items-center mx-auto">
          {/* First Div */}
          <div className="hidden md:block  md:col-span-4 lg:col-span-3">
            <img
              className="w-full h-[565px] rounded-[50px] transition-opacity duration-1000 ease-in-out"
              src={images[indices[0]]}
              alt="Image Group 1"
              style={{ opacity: opacity[0] }}
            />
          </div>

          {/* Second Div */}
          <div className="col-span-8 md:col-span-4">
            <img
              className="h-[700px] w-full rounded-[50px] transition-opacity duration-1000 ease-in-out"
              src={images[indices[1]]}
              alt="Image Group 2"
              style={{ opacity: opacity[1] }}
            />
          </div>

          {/* Third Div */}
          <div className="hidden lg:block lg:col-span-1">
            <img
              className="h-[565px] w-full rounded-[50px] transition-opacity duration-1000 ease-in-out object-cover"
              src={images[indices[2]]}
              alt="Image Group 3"
              style={{ opacity: opacity[2] }}
            />
          </div>
        </div>
      </div>
      <div className="about"></div>
    </div>
  );
};

export default Filmmaker;
