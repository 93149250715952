import React, { useState } from "react";
import Accordion from "./Accordion";
import { Icon } from "@iconify/react";
const collection = [
  {
    id: 1,
    question: "What services do you offer?",
    answer:
      "We provide a wide range of photography services, including portrait photography, wedding photography, event photography, product photography, and landscape photography. Our team is also skilled in post-production editing to ensure your images are of the highest quality.",
  },
  {
    id: 2,
    question: "How do I book a session?",
    answer:
      "We provide a wide range of photography services, including portrait photography, wedding photography, event photography, product photography, and landscape photography. Our team is also skilled in post-production editing to ensure your images are of the highest quality.",
  },
  {
    id: 3,
    question: "Do you travel for shoots?",
    answer:
      "We provide a wide range of photography services, including portrait photography, wedding photography, event photography, product photography, and landscape photography. Our team is also skilled in post-production editing to ensure your images are of the highest quality.",
  },
  {
    id: 4,
    question: "What is your pricing structure?",
    answer:
      "We provide a wide range of photography services, including portrait photography, wedding photography, event photography, product photography, and landscape photography. Our team is also skilled in post-production editing to ensure your images are of the highest quality.",
  },
  // {
  //   id: 5,
  //   question: "What services do you offer?",
  //   answer:
  //     "We provide a wide range of photography services, including portrait photography, wedding photography, event photography, product photography, and landscape photography. Our team is also skilled in post-production editing to ensure your images are of the highest quality.",
  // },
];
function BudgetModal({ handelBudgetModal, budgetModal }) {
  // eslint-disable-next-line no-unused-vars
  const [data, setdata] = useState(collection);
  const [activeId, setActiveId] = useState(null);

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto  bg-[#231F20B2] 
      ${budgetModal ? "block" : "hidden"}
      `}
    >
      {budgetModal && <style>{`body { overflow: hidden; }`}</style>}

      <div className="flex items-center justify-center h-full my-auto">
        <div className="w-[90%] md:w-[85%] lg:w-[80%] mx-auto bg-[#ffffff] p-10 rounded-xl  overflow-hidden  transform transition-all relative">
          <div className="absolute right-4 md:right-5 top-3 cursor-pointer">
            <Icon
              icon="zondicons:close-solid"
              className="text-3xl text-textcolor"
              onClick={handelBudgetModal}
            />
          </div>
          <div className="text-5xl text-textcolor text-center font-semibold mb-10">
            Get a Budget
          </div>
          <div className="space-y-1.5">
            {data.map((faq, index) => {
              return (
                <Accordion
                  key={faq.id}
                  id={faq.id}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={activeId === faq.id}
                  onToggle={() =>
                    setActiveId(activeId === faq.id ? null : faq.id)
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BudgetModal;
